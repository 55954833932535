import styled, { css } from 'styled-components';
import Modal from 'styled-react-modal';
import { XButton, LoadingContainer } from '../../../global.style';

const maxHeight = 300;

export const Wrapper = styled.div`
 a{
  display: block;
  padding: 5px;
  margin: 5px;
 }
`;


export const SearchContainer = styled.div`
 ${({ theme: { primary, shades, font } }: any) => `
    display: block;
    position: relative;
    background-color: ${shades[2]};
    outline: none;

    .icon-search {
      display: block;
      position: absolute;
      top: 20px;
      left: 18px;
      transform: translate(0, -50%);
      z-index: 100;
      color: ${primary};
      font-size: 20px;
    }

    & {
      div[label=""] {
        margin-bottom: 10px;

        & {
          input {
            border: none;
            padding-left: 50px;

            &::placeholder {
              color: ${shades[2]};
              font-size: ${font.size.text.lg};
            }
          }

          ::before {
            margin: 0;
          }
        }
      }
    }

    ${XButton} {
      top: 0 !important;
      right: 18px;
    }
 `}
`;

export const SearchDropdownContainer = Modal.styled`
  ${({ theme: { shades, boxShadow } }: any) => `
    display: block;
    position: absolute;
    // top: 62px;
    width: 100%;
    background-color: ${shades[0]};
    z-index: 100;
    box-shadow: ${boxShadow.md};
  `}
`;

export const LoadingContainerExtends = styled(LoadingContainer)`
  margin: 15px 0 0;
`;

export const SearchWrapper = styled(Wrapper)`
  ${({ theme: { shades } }: any) => `
    background-color: ${shades[0]};
    color: ${shades[5]};
    // max-height: ${maxHeight}px;
  `}
`;

export const SearchList = styled.ul`
  display: block;
  padding: 0;
  margin: 0 0 10px;
  overflow: auto;
  max-height: calc(${maxHeight}px - 80px);
`;

export const SearchListWrapper=styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
padding: 0px 0px 2px 15px;
`

export const SearchItem = styled.li`
  ${({ theme: { shades, primary } }: any) => `
    display: block;
    padding: 10px;
    margin: 0;
    box-sizing: border-box;
    color: ${shades[4]};
    transition: all 0.2s cubic-bezier(0.83, 0, 0.17, 1);

    &:active {
      color: ${shades[0]};
      background-color: ${primary};
    }
  `}
`;

export const CharityLogo = styled.div`
  ${({ theme: { shades }, logoURL }: any) => css`
    display: block;
    width: 35px;
    height: 35px;
    background-color: ${shades[0]};
    background-image: url('${logoURL}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
  `}
`;

export const SearchStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 18px;
  box-sizing: border-box;
`;

export const Status = styled.div`
  ${({ theme: { shades, font } }: any) => `
    display: block;
    position: relative;
    width: fit-content;
    text-transform: uppercase;
    height: 20px;
    color: ${shades[3]};
    font-size: ${font.size.text.md};
    font-weight: ${font.weight.bold};
  `}
`;

export const StatusOption = styled(Status)`
${({ theme: { primary } }: any) => `
  color: ${primary};
`}
`;

export const ButtonDiv = styled.div`
  ${({ theme: { primary, font, shades } }: any) => (
    css`
      display: flex;
      height: 44px;
      width: 100%;
      color: ${primary};
      background: transparent;
      border: 1px solid ${primary};
      font-size: ${font.size.text.md};
      font-weight: ${font.weight.bold};
      line-height: 18px;
      justify-content: center;
      align-items: center;
      transition: all 0.2s cubic-bezier(0.83, 0, 0.17, 1);

      &:active {
        color: ${shades[0]};
        background-color: ${primary};
      }
    `
  )}
`;
export const WrapperContent=styled.div`
padding:20px 20px 0;
`;
export const ContentButton = styled.button`
${({ theme: { shades, font, primary } }: any) => `
display: block;
color:${primary};
background-color:${shades[0]};
border: 1px solid ${primary};
height: 44px;
outline: none;
width: 100%;
font-weight:500;
font-size: ${font.size.text.lg};
line-height: 18px;
margin: 1rem 0;
`}`;
